export const searchHeaderGtm = {
    event: "MdlzcustomEvent",
    GAeventCategory: "searchHeader",
    GAeventAction: "enter or clickButton",
    GAeventLabel: "keyword",
    GAeventValue: 0,
    GAeventNonInteraction: false,
}

export const searchRecipeGtm = {
    event: "MdlzcustomEvent",
    GAeventCategory: "searchRecipe",
    GAeventAction: "enter or clickButton",
    GAeventLabel: "keyword",
    GAeventValue: 0,
    GAeventNonInteraction: false,
}

export const formSubmitObj = {
    event: "MdlzcustomEvent",
    GAeventCategory: "downloadForm",
    GAeventAction: "",
    GAeventLabel: "https://dev-subscription.api.mdlzapps.cloud/v1/subscribe/json",
    GAeventValue: 0,
    GAeventNonInteraction: false,
}

export const gaEventClasses = {
    buy_now: "event_buy_now",
    buy_now_choose_retailer: "event_buy_now_choose_retailer",
    buy_now_choose_product: "event_buy_now_choose_product",
    buy_now_eretailer_exit: "event_buy_now_eretailer_exit",
    where_to_buy: "event_where_to_buy",
    buy_now_add_to_cart: "event_buy_now_add_to_cart",
    buy_now_checkout_complete: "event_buy_now_checkout_complete",
    remove_from_cart: "event_remove_from_cart",
    view_cart: "event_view_cart",
    preview_cart: "event_preview_cart",
    checkout_now: "event_checkout_now",
    update_quantity: "event_update_quantity",
    buy_now_add_to_wishlist: "event_buy_now_add_to_wishlist",
    buy_now_add_payment_info: "event_buy_now_add_payment_info",
    continue_shopping: "event_continue_shopping",
    plan_frequency: "event_plan_frequency",
    start_plan: "event_start_plan",
    finish_plan: "event_finish_plan",
    update_plan: "event_update_plan",
    add_to_plan: "event_add_to_plan",
    remove_from_plan: "event_remove_from_plan",
    product_consultation_start: "event_product_consultation_start",
    product_consultation_reco: "event_product_consultation_reco",
    product_consultation_complete: "event_product_consultation_complete",
    product_consultation_feedback: "event_product_consultation_feedback",
    coupon_request: "event_coupon_request",
    coupon_print: "event_coupon_print",
    coupon_request_card: "event_coupon_request_card",
    coupon_request_scan: "event_coupon_request_scan",
    coupon_receiptscan: "event_coupon_receiptscan",
    coupon_cashback: "event_coupon_cashback",
    coupon_redemption: "event_coupon_redemption",
    gift_certificate_redemption: "event_gift_certificate_redemption",
    profile_register: "event_profile_register",
    profile_register_submit: "event_profile_register_submit",
    profile_register_complete: "event_profile_register_complete",
    profile_register_submit_error: "event_profile_register_submit_error",
    profile_optin_activation: "event_profile_optin_activation",
    profile_login: "event_profile_login",
    profile_login_submit: "event_profile_login_submit",
    profile_login_complete: "event_profile_login_complete",
    profile_login_submit_error: "event_profile_login_submit_error",
    profile_logout: "event_profile_logout",
    profile_register_cancel: "event_profile_register_cancel",
    profile_register_social: "event_profile_register_social",
    profile_email_signup: "event_profile_email_signup",
    search: "event_search",
    search_recipe: "event_search_recipe",
    searchBar: "view_search_results",
    print_page: "event_print_page",
    video_play: "event_video_play",
    file_download: "event_file_download",
    product_review_view: "event_product_review_view",
    open_mobile_app: "event_open_mobile_app",
    view_product_detail_page: "event_view_product_detail_page",
    view_more_details: "event_view_more_details",
    view_article_page: "event_view_article_page",
    special_offer: "event_special_offer",
    external_link: "event_external_link",
    button_click: "event_button_click",
    filter_click: "event_filter_click",
    menu_click: "event_menu_click",
    internal_link: "event_internal_link",
    image_click: "event_image_click",
    close_window: "event_close_window",
    banner_click: "event_banner_click",
    slider: "event_slider",
    scroll_depth: "event_scroll_depth",
    footer_click: "event_footer_click",
    product_review: "event_product_review",
    submit_review: "event_submit_review",
    submit_review_approved: "event_submit_review_approved",
    submit_rating_score: "event_submit_rating_score",
    preview_review: "event_preview_review",
    cancel_review: "event_cancel_review",
    share: "event_share",
    socialmedia_exit: "event_socialmedia_exit",
    survey_start: "event_survey_start",
    survey_complete: "event_survey_complete",
    survey_question: "event_survey_question",
    informational_action: "event_informational_action",
    logo: "event_logo",
    filter_sortby: "event_filter_sortby",
    newsletter_signup: "event_newsletter_signup",
    measurement: "event_measurement",
}

export const gaEventLabels = {
    arrowRight: "clickArrowRight",
    arrowLeft: "clickArrowLeft",
    logo: "royal logo",
    done: "done",
    edit: "edit",
    searchRecipe: "searchRecipe",
    searchHeader: "searchHeader",
    filterRecipes: "filterRecipes",
    dots: "dots",
    seeMore: "See More",
    printPage: "print page",
    savePage: "save page",
}
