import React from "react"

function Refresh() {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.9929 12.4414C18.486 12.4458 18.06 12.8218 17.9948 13.3222C17.5118 16.6072 14.4452 18.8804 11.1454 18.3996C7.84565 17.9188 5.56214 14.8661 6.0451 11.5811C6.52807 8.2962 9.59462 6.02299 12.8944 6.50378C14.0192 6.66766 15.0747 7.14428 15.9395 7.87885L15.182 8.63294C14.9202 8.89357 14.9203 9.31612 15.1821 9.57669C15.3083 9.70225 15.4795 9.77257 15.6579 9.77207H18.7321C19.1023 9.77207 19.4025 9.4733 19.4025 9.10475V6.04439C19.4024 5.67583 19.1022 5.37713 18.732 5.3772C18.5543 5.37723 18.3838 5.44752 18.2582 5.57258L17.3653 6.46145C14.0433 3.53156 8.96441 3.83724 6.02122 7.14425C3.07804 10.4513 3.38516 15.5073 6.70711 18.4372C10.0291 21.3671 15.108 21.0614 18.0512 17.7544C19.0872 16.5903 19.76 15.1507 19.987 13.6119C20.0759 13.0609 19.6993 12.5426 19.1459 12.4541C19.0953 12.446 19.0441 12.4418 18.9929 12.4414Z"
                fill="#1D1D1B"
            />
        </svg>
    )
}

export default Refresh
