import { useState, useEffect } from "react"

const useSubscriptionBannerState = (initialDelay = 500) => {
    const [bannerVisible, setBannerVisibleState] = useState(false)

    useEffect(() => {
        const timer = setTimeout(() => {
            const isBannerClosed = sessionStorage.getItem("isBannerClosed")
            if (isBannerClosed !== "true") {
                setBannerVisibleState(true)
            }
        }, initialDelay)

        return () => clearTimeout(timer)
    }, [initialDelay])

    const setBannerVisible = isVisible => {
        setBannerVisibleState(isVisible)
        if (!isVisible) {
            sessionStorage.setItem("isBannerClosed", "true")
        } else {
            sessionStorage.removeItem("isBannerClosed")
        }
    }

    return [bannerVisible, setBannerVisible]
}

export default useSubscriptionBannerState
