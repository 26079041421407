import React from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import Download from "../../../assets/icons/24x24/Download"
import Refresh from "../../../assets/icons/24x24/Refresh"
import ChevronRight from "../../../assets/icons/24x24/ChevronRight"
import ChevronTop from "../../../assets/icons/24x24/ChevronTop"
import Stare from "../../../assets/icons/24x24/Stare"
import Search from "../../../assets/icons/24x24/Search"

const icons = {
    Download: <Download />,
    Refresh: <Refresh />,
    ChevronRight: <ChevronRight />,
    ChevronTop: <ChevronTop />,
    Stare: <Stare />,
    Search: <Search />,
}

export const iconNames = Object.keys(icons).reduce((acc, icon) => {
    acc[icon] = icon
    return acc
}, {})

export const variants = {
    primary: "primary",
    secondary: "secondary",
}

const Button = props => {
    const {
        className,
        text,
        children,
        dataActionDetail,
        onClick,
        disabled,
        id,
        gaEventClass = "",
        variant,
        icon,
    } = props

    const finalClassName = clsx(
        variant === variants.primary && "button-primary",
        variant === variants.secondary && "button-secondary",
        gaEventClass,
        className
    )

    const finalIcon = icons[icon] || null
    return (
        <button
            type="button"
            aria-label={text}
            className={finalClassName}
            data-action-detail={dataActionDetail}
            onClick={onClick}
            disabled={disabled}
            id={id}
        >
            {children || text}
            {!!finalIcon && finalIcon}
        </button>
    )
}

Button.propTypes = {
    className: PropTypes.string,
    text: PropTypes.string,
    children: PropTypes.any,
    dataActionDetail: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    gaEventClass: PropTypes.string,
    variant: PropTypes.oneOf(["primary", "secondary"]),
    icon: PropTypes.oneOf([null, "Download", "Refresh", "ChevronRight", "ChevronTop", "Stare", "Search"]),
}

export default Button
