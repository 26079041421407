export const defaultStyle = {
    wrapper: "relative bg-burgundy",
    container: "section-wrapper flex flex-col gap-8 pt-12 pb-[22px]",
    logoWrapper: "flex justify-center",
    logoImage: "w-[150px] lg:w-[unset]",
    columns: "flex flex-col gap-8 w-full items-center lg:justify-between lg:items-start lg:flex-row lg:gap-5",
    column: "flex flex-col gap-8",
    columnTitle: "navigation-menu footer-navigation-shadow text-center text-white lg:text-left",
    navigationLink: "body-l font-semibold text-center text-white lg:text-left",
    socialsWrapper: "flex flex-row gap-[21px] items-center justify-center",
    lastRow: "flex flex-col gap-8 w-full items-center lg:justify-between lg:items-end lg:flex-row lg:gap-5",
    copyRight: "body-xs font-normal text-white text-center lg:text-right",
}
