export const defaultStyle = {
    wrapper: "relative",
}

export const Homepage = {
    aWrapper: "block translate-y-0 hover:translate-y-[-4px] transition-all duration-300 ease-in-out w-fit",
    wrapper:
        "relative bg-white max-w-full w-[312px] px-[8px] pt-[8px] pb-[16px] flex flex-col gap-[22px] rounded-[5px] recipe-card-filter-default md:w-[342px] lg:w-[270px] xlg:w-[345px]",
    imageWrapper: "relative w-full h-[303px] md:h-[220px] lg:h-[240px] xlg:h-[303px]",
    img: "object-cover w-full h-full",
    content: "flex flex-col gap-[8px] px-[6px] justify-between flex-1 md:px-[20px] lg:px-[8px] xlg:px-[20px]",
    title: "text-black subtitle-big line-clamp-2 min-h-[50px]",
    tags: "flex flex-wrap gap-[8px] min-h-[69px] max-h-[69px] h-[69px]",
}
export const RecipePage = {
    aWrapper: "block translate-y-0 hover:translate-y-[-4px] transition-all duration-300 ease-in-out w-fit",
    wrapper:
        "relative bg-white max-w-full w-[358px] px-[8px] pt-[8px] pb-[16px] flex md:flex-col gap-[12px] md:gap-[22px] rounded-[5px] recipe-card-filter-recipe-page md:w-[342px] lg:w-[270px] xlg:w-[345px]",
    imageWrapper: "relative w-1/2 md:w-full h-[303px] md:w-full md:h-[220px] lg:h-[240px] xlg:h-[303px]",
    img: "object-cover w-full h-full",
    content:
        "w-1/2 flex flex-col gap-[8px] px-[6px] justify-between flex-1 md:w-full md:px-[20px] lg:px-[8px] xlg:px-[20px]",
    title: "pt-2 md:pt-0 text-black subtitle-big line-clamp-6 md:line-clamp-2 md:min-h-[50px]",
    tags: "flex flex-wrap gap-[8px] min-h-[69px]",
}

export const RecipeDetailPage = {
    aWrapper: "block translate-y-0 hover:translate-y-[-4px] transition-all duration-300 ease-in-out w-fit",
    wrapper:
        "relative bg-white max-w-full w-[312px] px-[8px] pt-[8px] pb-[16px] flex flex-col gap-[22px] rounded-[5px] recipe-card-filter-default md:w-[342px] lg:w-[270px] xlg:w-[345px]",
    imageWrapper: "relative w-full h-[303px] md:h-[220px] lg:h-[240px] xlg:h-[303px]",
    img: "object-cover w-full h-full",
    content: "flex flex-col gap-[8px] px-[6px] justify-between flex-1 md:px-[20px] lg:px-[8px] xlg:px-[20px]",
    title: "text-black subtitle-big line-clamp-2 min-h-[50px]",
    tags: "flex flex-wrap gap-[8px] min-h-[69px] max-h-[69px] h-[69px]",
}
