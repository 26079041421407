import React from "react"
import PropTypes from "prop-types"

import { defaultStyle as style } from "./RecipeInfoBlock-tw-styles"

import RecipePreparation from "./RecipePreparation/RecipePreparation"
import RecipeIngredients from "./RecipeIngredients/RecipeIngredients"

const RecipeInfoBlock = props => {
    const { id, recipe } = props
    const { PreparationSteps, IngredientList } = recipe

    return (
        <section id={id} className={style.wrapper}>
            <div className={style.content}>
                <div className={style.container}>
                    <RecipePreparation id="RecipePreparation" steps={PreparationSteps.PreparationTypes} />
                    <RecipeIngredients id="RecipeIngredients" list={IngredientList} />
                </div>
            </div>
        </section>
    )
}

RecipeInfoBlock.propTypes = {
    id: PropTypes.string,
    recipe: PropTypes.object,
}

export default RecipeInfoBlock
