import React from "react"

function ChevronLeft() {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.0003 21.8853L17.0003 19.8853L8.0003 10.8853L6.0003 12.8853L15.0003 21.8853Z" fill="#1D1D1B" />
            <path d="M17.0003 5.88525L15.0003 3.88525L6.0003 12.8853L8.00026 14.8852L17.0003 5.88525Z" fill="#1D1D1B" />
        </svg>
    )
}

export default ChevronLeft
