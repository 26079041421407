import React from "react"
import PropTypes from "prop-types"
import * as styles from "./RecipeCard-tw-styles"
import ResponsiveImage from "../ResponsiveImage/ResponsiveImage"
import Link from "../Link/Link"
import { labels as recipeLabels } from "../../../constants/recipe.constants"
import Tags from "../Tags/Tags"
import { convertToSecureUrl } from "../../../helpers/utils"
import { gaEventClasses } from "../../../constants/gtm.constants"
function RecipeCard({ recipe, variant }) {
    const style = styles?.[variant] || styles?.defaultStyle
    const recipeImageUrl =
        recipe?.PhotoURLAdditional.find(imageObj => imageObj?.PhotoTypeID === recipeLabels.CARD_PHOTO_TYPE_ID)
            ?.PhotoLink ||
        recipe?.PhotoURLAdditional[0]?.PhotoLink ||
        recipeLabels.RECIPE_IMAGE_FALLBACK_URL
    const renderContent = () => (
        <div className={style?.wrapper}>
            <div className={style?.imageWrapper}>
                <ResponsiveImage
                    contentfulImagesComponent={{
                        imageDesktop: { url: convertToSecureUrl(recipeImageUrl) },
                    }}
                    className={style?.img}
                />
            </div>
            <div className={style?.content}>
                <h4 className={style?.title} title={recipe?.RecipeName}>
                    {recipe?.RecipeName}
                </h4>
                <div className={style?.tags}>
                    <Tags classifications={recipe?.Classifications} />
                </div>
            </div>
        </div>
    )

    return recipe?.SeoPageName ? (
        <Link href={`${recipeLabels.slug}/${recipe.SeoPageName}`} className={style?.aWrapper + ' ' + gaEventClasses.slider}>
            {renderContent()}
        </Link>
    ) : (
        renderContent()
    )
}

export default RecipeCard

RecipeCard.propTypes = {
    recipe: PropTypes.object,
    variant: PropTypes.string,
}
