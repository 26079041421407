const TAGS_CONSTANT = {
    cookingType: 22023,
    occasion: 22030,
    special: 22037,
}

const DIFFICULTIES_CONSTANT = {
    EASY: 5350,
    MEDIUM: 5351,
    HARD: 5352,
}

const PORTFOLIO_SORT_CONSTANT = 26302
const RECIPE_CLASSIFICATION_SORT_CONSTANT = 22154

const mondelezAPI = {
    brandID: 26027,
    siteID: "royales2023",
    websiteID: 154,
    apiLocale: "eses",
    languageID: 2,
    sectorID: "20234",
    classificationList: [
        {
            type: "cookingType",
            id: TAGS_CONSTANT.cookingType,
        },
        {
            type: "occasion",
            id: TAGS_CONSTANT.occasion,
        },
        {
            type: "special",
            id: TAGS_CONSTANT.special,
        },
    ],
    timeSpan: [
        {
            type: "prepTime",
            value: 43,
        },
        {
            type: "totalTime",
            value: 41,
        },
    ],
}

module.exports = {
    mondelezAPI,
    TAGS_CONSTANT,
    DIFFICULTIES_CONSTANT,
    PORTFOLIO_SORT_CONSTANT,
    RECIPE_CLASSIFICATION_SORT_CONSTANT,
}
