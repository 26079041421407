export const defaultStyle = {
    wrapper:
        "hero-card-filter-shadow relative w-full flex flex-col rounded-[5px] bg-red p-[30px] min-h-[256px] md:min-h-[220px] lg:min-h-[280px]",
    heading: "recipe-hero-card-title text-white mb-[32px]",
    actions: "actions flex flex-col justify-center mt-auto space-y-[32px]",
    tags: "tags flex items-center space-x-[10px]",
    ctaWrapper: "cta flex justify-between items-center",
    cta: "relative text-white flex items-center space-x-[8px] body-s uppercase",
    linkCopiedPopup:
        "pointer-events-none absolute top-[30px] left-0 !ml-0 z-[1] flex bg-beigeWithOverlay7 px-2 py-2 transition-opacity duration-200",
    linkCopiedSpan: "whitespace-nowrap body-s text-black",
    icon: "fill-white",
}
