import React from "react"
import PropTypes from "prop-types"
import RecipeCard from "../UI/RecipeCard/RecipeCard"
import { v4 as uuidv4 } from "uuid"
import Slider from "react-slick"
import * as tw from "../../../tailwind.config"
import { NextArrow, PrevArrow } from "./Arrows"
import CustomPaging from "../UI/CustomPaging/CustomPaging"

function RecipeCardsSlider({ recipes, variant }) {
    const customPagingVariant = variant === "RecipeDetailPage" ? "darkRed" : "yellow"
    const settings = {
        dots: true,
        arrows: true,
        swipe: true,
        infinite: recipes.length > 3,
        rows: 1,
        className: "recipe-cards-slider",
        centerMode: false,
        autoplay: false,
        variableWidth: true,
        dotsClass: "slick-dots-wrapper bottom-[13.5px]",
        customPaging: () => {
            return <CustomPaging variant={customPagingVariant} />
        },
        prevArrow: <PrevArrow variant="light" isHidden={false} />,
        nextArrow: <NextArrow variant="light" isHidden={false} />,
        responsive: [
            {
                breakpoint: 9999,
                settings: {
                    slidesToShow: Math.min(recipes.length, 3),
                },
            },
            {
                breakpoint: Number(tw.theme.screens.xlg.slice(0, -2)) - 1,
                settings: {
                    slidesToShow: Math.max(recipes.length - 1, 3),
                },
            },
            {
                breakpoint: Number(tw.theme.screens.lg.slice(0, -2)) - 1,
                settings: {
                    slidesToShow: Math.max(recipes.length - 1, 2),
                },
            },
            {
                breakpoint: Number(tw.theme.screens.md.slice(0, -2)) - 1,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    }
    if (!recipes || recipes.length === 0) return <></>
    if (recipes.length === 1) return <RecipeCard recipe={recipes[0]} variant={variant} />
    return (
        <div>
            <Slider {...settings}>
                {recipes.map(recipe => {
                    return <RecipeCard recipe={recipe} variant={variant} key={uuidv4()} />
                })}
            </Slider>
        </div>
    )
}

export default RecipeCardsSlider

RecipeCardsSlider.propTypes = {
    recipes: PropTypes.array,
    variant: PropTypes.string,
}
