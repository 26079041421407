import React from "react"

function ChevronRight() {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 21.4493L7 19.4493L16 10.4494L18 12.4493L9 21.4493Z" fill="#1D1D1B" />
            <path d="M7.00004 5.44934L9.00004 3.44934L18 12.4493L16 14.4493L7.00004 5.44934Z" fill="#1D1D1B" />
        </svg>
    )
}

export default ChevronRight
