import React from "react"
import PropTypes from "prop-types"

import { defaultStyle as style } from "./SubscriptionFormBanner-tw-styles"
import Button from "../../../UI/Button/Button"
import useSubscriptionBannerState from "../../../../hooks/useSubscriptionBannerState"

const SubscriptionFormBanner = props => {
    const { title, description, primaryCtaLabel, secondaryCtaLabel, sectionId } = props
    const [bannerVisible, setBannerVisible] = useSubscriptionBannerState()
    const primaryButtonClickHandler = () => {
        const section = document.getElementById(sectionId)
        section?.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
        })
        setBannerVisible(false)
    }
    const secondaryButtonClickHandler = () => {
        setBannerVisible(false)
    }
    return (
        <div
            className={style.wrapper}
            style={{
                bottom: !bannerVisible ? "-100px" : "0",
                opacity: !bannerVisible ? "0" : "1",
                pointerEvents: !bannerVisible ? "none" : "auto",
            }}
        >
            <div className={style.content}>
                <div className={style.copyWrapper}>
                    <h5 className={style.title}>{title}</h5>
                    <p className={style.description}>{description}</p>
                </div>
                <div className={style.ctaWrapper}>
                    <Button variant="primary" onClick={primaryButtonClickHandler} text={primaryCtaLabel}>
                        {primaryCtaLabel}
                    </Button>
                    <Button variant="secondary" onClick={secondaryButtonClickHandler} text={secondaryCtaLabel}>
                        {secondaryCtaLabel}
                    </Button>
                </div>
            </div>
        </div>
    )
}

SubscriptionFormBanner.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    primaryCtaLabel: PropTypes.string,
    secondaryCtaLabel: PropTypes.string,
    sectionId: PropTypes.string,
}

export default SubscriptionFormBanner
