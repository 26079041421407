import React from "react"
import PropTypes from "prop-types"

function Hamburger({ mobileNavOpen, setMobileNavOpen }) {
    return (
        <button
            type="button"
            aria-label="Toggle navigation menu"
            className="absolute right-4 md:right-[unset] md:relative flex flex-col items-end justify-between bg-transparent px-[2px] py-[7px] cursor-pointer lg:hidden"
            style={{
                width: "32px",
                height: "32px",
            }}
            onClick={() => setMobileNavOpen(prevState => !prevState)}
        >
            <span
                className="bg-black transition duration-300 ease-in-out transform"
                style={{
                    width: "28px",
                    height: "2px",
                    transformOrigin: "8px 1px",
                    transform: mobileNavOpen ? "rotate(45deg) translate(2px, 2px) scaleX(1.20)" : "rotate(0deg)",
                }}
            />
            <span
                className="bg-black transition duration-300 ease-in-out transform"
                style={{
                    width: "28px",
                    height: "2px",
                    transformOrigin: "0 100%",
                    transform: mobileNavOpen ? "scale(0)" : "scale(1)",
                }}
            />
            <span
                className="bg-black transition duration-300 ease-in-out transform"
                style={{
                    width: mobileNavOpen ? "28px" : "18px",
                    height: "2px",
                    transformOrigin: "8px 0px",
                    transform: mobileNavOpen ? "rotate(-45deg) translate(2px, -2px) scaleX(1.20)" : "rotate(0deg)",
                }}
            />
        </button>
    )
}

export default Hamburger

Hamburger.propTypes = {
    mobileNavOpen: PropTypes.bool,
    setMobileNavOpen: PropTypes.func,
}
