import React from "react"

function CheckMark() {
    return (
        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M6.36365 9.10808L12.022 3.44973L10.6078 2.03552L4.94943 7.69386L1.41392 4.15835L-0.00029575 5.57256L3.53522 9.10808L3.5352 9.10809L4.94942 10.5223L4.94943 10.5223L4.94945 10.5223L6.36367 9.1081L6.36365 9.10808Z" fill="#E42313"/>
        </svg>
    )
}

export default CheckMark
