import React from "react"
import PropTypes from "prop-types"

function Download({styling}) {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" className={styling} xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.2902 18.5066C13.1872 19.609 11.3994 19.6084 10.2971 18.5054L7.27499 15.487C6.90723 15.1195 6.90701 14.5235 7.27451 14.1557C7.642 13.7879 8.23804 13.7877 8.6058 14.1552L11.3446 16.8921L11.3531 2.3905C11.3531 1.87071 11.7745 1.44934 12.2943 1.44934C12.814 1.44934 13.2354 1.87071 13.2355 2.39055L13.2289 16.9101L15.9828 14.1552C16.3411 13.8299 16.8877 13.8293 17.2467 14.1539C17.6322 14.5025 17.6622 15.0977 17.3136 15.4833L14.2915 18.5054L14.2902 18.5066ZM21.7059 17.4491C21.7059 16.9293 22.1273 16.5079 22.6471 16.5079C23.1669 16.5079 23.5882 16.9293 23.5882 17.4491V21.2138C23.5882 22.7732 22.3241 24.0374 20.7647 24.0374H3.82353C2.26415 24.0374 1 22.7732 1 21.2138V17.4491C1 16.9293 1.42137 16.5079 1.94121 16.508C2.461 16.508 2.88237 16.9293 2.88237 17.4491V21.2138C2.88237 21.7336 3.30374 22.155 3.82353 22.155H20.7648C21.2845 22.155 21.7059 21.7336 21.7059 21.2138V17.4491Z"
            />
        </svg>
    )
}

Download.propTypes = {
    styling: PropTypes.string,
}

export default Download
