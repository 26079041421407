import React from "react"
import PropTypes from "prop-types"
import { gaEventClasses, gaEventLabels } from "../../../constants/gtm.constants"

function CustomPaging({ variant, ...rest }) {
    switch (variant) {
        case "darkRed":
            return (
                <div
                    className={`slick-darkRed-bg-dot ${gaEventClasses.button_click}`}
                    data-action-detail={gaEventLabels.dots}
                    {...rest}
                >
                    <div className="slick-darkRed-bg-dot-line"></div>
                </div>
            )
        case "yellow":
        default:
            return (
                <div
                    className={`slick-yellow-bg-dot ${gaEventClasses.button_click}`}
                    data-action-detail={gaEventLabels.dots}
                    {...rest}
                >
                    <div className="slick-yellow-bg-dot-line"></div>
                </div>
            )
    }
}
export default CustomPaging

CustomPaging.propTypes = {
    variant: PropTypes.string,
}
