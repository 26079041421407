export const defaultStyle = {
    wrapper: "hidden",
}

export const LANDSCAPE = {
    wrapper: "relative pt-8 bg-beige px-[16px] md:px-[32px] lg:px-0",
    content:
        "z-[3] relative mx-auto w-full max-w-[640px] aspect-[16/9] recipe-video-filter rounded-[8px] overflow-hidden",
    buttonOverlay: "absolute top-0 right-0 bottom-0 left-0 block opacity-0",
}
export const PORTRAIT = {
    wrapper: "relative pt-8 bg-beige px-[16px] md:px-[32px] lg:px-0",
    content:
        "z-[3] relative mx-auto w-full max-w-[360px] aspect-[9/16] recipe-video-filter rounded-[8px] overflow-hidden",
    buttonOverlay: "absolute top-0 right-0 bottom-0 left-0 block opacity-0",
}
