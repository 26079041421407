import { useState } from "react"
import { useGlobalContext } from "../context/GlobalContext"
import { v4 as uuidv4 } from "uuid"
import { formSubmitObj } from "../constants/gtm.constants"
import axios from "axios"

const postCampaignData = async payload => {
    try {
        return axios.post(process.env.GATSBY_FORM_API, payload)
    } catch (error) {
        throw new Error(error)
    }
}

const useSubscriptionForm = () => {
    if (!process.env.GATSBY_FORM_API) {
        console.warn("GATSBY_FORM_API not set")
    }
    if (!process.env.GATSBY_APP_SITE_KEY) {
        console.warn("GATSBY_APP_SITE_KEY not set")
    }

    const [email, setEmail] = useState("")
    const [emailIsFocused, setEmailIsFocused] = useState(false)
    const [newsletterChecked, setNewsletterChecked] = useState(false)
    const [thirdPartyChecked, setThirdPartyChecked] = useState(false)
    const [recaptchaValue, setRecaptchaValue] = useState(null)
    const [errors, setErrors] = useState({})
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [submitSuccess, setSubmitSuccess] = useState(false)
    const [isnewsletterConfirmationExpended, setisnewsletterConfirmationExpended] = useState(false)
    const [isthirdPartyConfirmationExpended, setisthirdPartyConfirmationExpended] = useState(false)

    const context = useGlobalContext()
    const labels = context.labels

    const errorLabels = {
        emailRequired: "Email is required.",
        emailFormat: "Invalid email format.",
        newsletter: "You must accept the privacy policy.",
        captcha: "reCAPTCHA verification is required",
        submit: "There was an error submitting the form. Please try again later.",
        ...context?.labels?.subscriptionForm?.errorHandling,
    }

    const handleInputChange = e => setEmail(e.target.value)
    const handleNewsletterCheckboxChange = e => setNewsletterChecked(e.target.checked)
    const handleThirdPartyCheckboxChange = e => setThirdPartyChecked(e.target.checked)

    const handleRecaptchaChange = value => setRecaptchaValue(value)

    const newsLettertoggleExpand = (e) => {
        e.preventDefault();
        setisnewsletterConfirmationExpended(!isnewsletterConfirmationExpended)
    }

    const thirdPartytoggleExpand = (e) => {
        e.preventDefault();
        setisthirdPartyConfirmationExpended(!isthirdPartyConfirmationExpended)
    }

    const isValidEmail = email => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return emailRegex.test(email)
    }

    const handleSubmit = async e => {
        e.preventDefault()
        let currentError = false

        setErrors({})

        if (!email.trim()) {
            currentError = true
            setErrors(prevErrors => ({ ...prevErrors, email: errorLabels.emailRequired }))
        } else if (!isValidEmail(email)) {
            currentError = true
            setErrors(prevErrors => ({ ...prevErrors, email: errorLabels.emailFormat }))
        }

        if (!newsletterChecked) {
            currentError = true
            setErrors(prevErrors => ({ ...prevErrors, newsletter: errorLabels.newsletter }))
        }

        if (!recaptchaValue) {
            currentError = true
            setErrors(prevErrors => ({ ...prevErrors, recaptcha: errorLabels.captcha }))
        }

        if (currentError) return

        const requestBody = {
            email: email,
            confirmNewsletter: newsletterChecked ? "1" : "0",
            confirm3rdParty: thirdPartyChecked ? "1" : "0",
            group: uuidv4(),
        }
        const payload = {
            requestDomain: "royalcontigo-com",
            requestCountry: "es",
            requestLanguage: "es",
            requestCampaign: "ROES240201 Royal ES Newsletter Registration",
            requestTransaction: "ROES240201 Royal ES Newsletter Registration (IN)",
            "g-recaptcha-response": recaptchaValue,
            requestBody,
        }
        setIsSubmitting(true)
        try {
            const response = await postCampaignData(payload)

            if (response.status !== 200) {
                throw new Error("Server response was not ok.")
            }

            setSubmitSuccess(true)
            if (window?.dataLayer) {
                formSubmitObj.GAeventAction = { ...requestBody, submit: "success" }
                if (location?.href) {
                    formSubmitObj.GAeventLabel = location.href
                }
                window.dataLayer.push({ ...formSubmitObj })
            }
        } catch (error) {
            setErrors(prevErrors => ({ ...prevErrors, submit: errorLabels.submit }))
            if (window?.dataLayer) {
                formSubmitObj.GAeventAction = { ...requestBody, submit: "failure" }
                if (location?.href) {
                    formSubmitObj.GAeventLabel = location.href
                }
                window.dataLayer.push({ ...formSubmitObj })
            }
        } finally {
            setIsSubmitting(false)
        }
    }

    return {
        email,
        emailIsFocused,
        setEmailIsFocused,
        newsletterChecked,
        thirdPartyChecked,
        recaptchaValue,
        errors,
        isSubmitting,
        labels,
        handleInputChange,
        handleNewsletterCheckboxChange,
        handleThirdPartyCheckboxChange,
        handleRecaptchaChange,
        handleSubmit,
        submitSuccess,
        isnewsletterConfirmationExpended,
        newsLettertoggleExpand,
        isthirdPartyConfirmationExpended,
        thirdPartytoggleExpand,
        recaptchaKey: process.env.GATSBY_APP_SITE_KEY,
    }
}

export default useSubscriptionForm
