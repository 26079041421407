export const defaultStyle = {
    wrapper: "bg-beige",
    content: "section-wrapper max-w-[950px] mx-auto pt-8 pb-2",
    infoBoxWrapper: "flex justify-between gap-2 md:gap-5",
    infoBox:
        "flex-1 flex flex-col items-center text-center justify-between text-center px-[12px] py-[14px] gap-2 border-black border-[1px] rounded-[15px] button-box-shadow bg-beige md:gap-2.5",
    infoBoxTitle: "body-s font-semibold md:body-m",
    infoBoxCaption: "body-s font-bold md:body-m",
    description: "body-s md:body-m mt-[40px] md:mt-[60px] [&_a]:underline",
}
