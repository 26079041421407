export const gTagScriptConsent = ` 
window.dataLayer = window.dataLayer || [];
function gtag() {
 dataLayer.push(arguments);
}
gtag('consent', 'default', {
 ad_storage: "denied",
 analytics_storage: "denied",
 functionality_storage: "denied",
 personalization_storage: "denied",
 security_storage: "denied",
 ad_user_data: "denied",
 ad_personalization: "denied",
 'wait_for_update': 500
});
`