import React from "react"

function Search() {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="17.5631" y="16.9493" width="7" height="2" transform="rotate(45 17.5631 16.9493)" fill="#1D1D1B" />
            <circle cx="10.0006" cy="10.4493" r="7.5" stroke="#1D1D1B" strokeWidth="2" />
        </svg>
    )
}

export default Search
