import React from "react"
import PropTypes from "prop-types"

import { useGlobalContext } from "../../../context/GlobalContext"
import { defaultStyle as style } from "./RecipeSimilarSlider-tw-styles"

import RecipeCardsSlider from "../../RecipeCardsSlider/RecipeCardsSlider"
import Link from "../Link/Link"

const RecipeSimilarSlider = props => {
    const { id, recipes } = props
    const { labels } = useGlobalContext()

    return (
        <section id={id} className={style.wrapper}>
            <div className={style.container}>
                <h1 className={style.headline}>{labels?.recipesDetail?.similar}</h1>
                <RecipeCardsSlider recipes={recipes} variant="RecipeDetailPage" />
                <div className={style.ctaWrapper}>
                    <Link url={labels?.recipesDetail?.url} className={style.cta}>
                        {labels?.common?.seeAll}
                    </Link>
                </div>
            </div>
        </section>
    )
}

RecipeSimilarSlider.propTypes = {
    id: PropTypes.string,
    recipes: PropTypes.array,
}

export default RecipeSimilarSlider
