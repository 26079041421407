/** @type {import('tailwindcss').Config} */

const colors = {
    white: "#FFFFFF",
    beige: "#F2E5CB",
    beigeWithOverlay7: "#E1D5BD",
    beigeWithOverlay12: "#D5CAB3",
    grey: "#B3B3B2",
    darkGrey: "#444444",
    black: "#1D1D1B",
    red: "#E42313",
    darkRed: "#BC1A0E",
    burgundy: "#9E1D06",
    blue: "#009FE3",
    blueWithOverlay7: "#0094D3",
    blueWithOverlay12: "#008CC8",
    yellow: "#FFED00",
}

module.exports = {
    content: [
        "./src/pages/**/*.{js,jsx,ts,tsx}",
        "./src/templates/**/*.{js,jsx,ts,tsx}",
        "./src/components/**/*.{js,jsx,ts,tsx}",
        "./src/assets/**/*.{js,jsx,ts,tsx}",
    ],
    theme: {
        screens: {
            xlg: "1920px",
            lg: "1440px",
            md: "768px",
            sm: "390px",
        },
        fontFamily: {
            "young-serif": "Young Serif",
            poppins: "Poppins",
        },
        extend: {
            colors: {
                ...colors,
            },
            stroke: {
                ...colors,
            },
            fill: {
                ...colors,
            },
            placeholderColor: {
                ...colors,
            },
            flexGrow: {},
            spacing: {},
            fontSize: {},
            lineHeight: {},
            borderWidth: {},
            borderRadius: {},
            inset: {
                auto: "auto",
            },
            height: {},
            minHeight: {
                auto: "auto",
            },
            maxWidth: {},
            minWidth: {},
            maxHeight: {},
            opacity: {},
            zIndex: {},
            gap: {},
            backgroundImage: {},
            translate: {},
            transitionDuration: {},
            rotate: {},
            boxShadow: {},
            fontFamily: {},
        },
    },
    variants: {
        extend: {
            stroke: ["hover"],
        },
    },
    plugins: [
        function ({ addVariant }) {
            addVariant("child", "& > *")
            addVariant("child-hover", "& > *:hover")
            addVariant("rect-descenders", "& rect")
            addVariant("circle-descenders", "& circle")
            addVariant("path-descenders", "& path")
            addVariant("svg-descenders", "& svg")
            addVariant("img-descenders", "& img")
        },
    ],
}
