import React from "react"

export const WaveLg = () => {
    return (
        <svg
            width="100%"
            height="61"
            viewBox="0 0 1920 61"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
        >
            <g clipPath="url(#clip0_88_14036)">
                <path
                    d="M0 0.968262C0 0.968262 345 48.9604 960 49.7021C1575 50.4439 1920 0.968262 1920 0.968262V60.9683H0V0.968262Z"
                />
            </g>
            <defs>
                <clipPath id="clip0_88_14036">
                    <rect width="100%" height="60" fill="white" transform="translate(0 0.968262)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export const WaveMd = () => {
    return (
        <svg
            width="100%"
            height="41"
            viewBox="0 0 768 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
        >
            <g clipPath="url(#clip0_57_1974)">
                <path
                    d="M0 0.968262C0 0.968262 138 35.9683 384 36.9683C630 37.9683 768 0.968262 768 0.968262V81.858H0V0.968262Z"
                />
            </g>
            <defs>
                <clipPath id="clip0_57_1974">
                    <rect width="100%" height="40" fill="white" transform="translate(0 0.968262)" />
                </clipPath>
            </defs>
        </svg>
    )
}
