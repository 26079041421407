import React from "react"
import PropTypes from "prop-types"
import ChevronLeft from "../../assets/icons/24x24/ChevronLeft"
import ChevronRight from "../../assets/icons/24x24/ChevronRight"
import { gaEventClasses, gaEventLabels } from "../../constants/gtm.constants"

const arrowClass =
    "hidden md:flex items-center justify-center w-[48px] h-[48px] button-box-shadow rounded-full cursor-pointer svg-descenders:w-[12px] svg-descenders:h-[12px] lg:w-[64px] lg:h-[64px] lg:svg-descenders:w-[unset] lg:svg-descenders:h-[unset]"
const arrowTheme = {
    default: "bg-beige hover:bg-beigeWithOverlay7 active:bg-beigeWithOverlay12 disabled:bg-grey",
    light: "bg-beige hover:bg-beigeWithOverlay7 active:bg-beigeWithOverlay12 disabled:bg-grey",
    dark: "bg-red path-descenders:fill-white",
}
const prevArrowPosition = "absolute top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/2 z-[3]"
const nextArrowPosition = "absolute top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2 z-[3]"

function PrevArrow(props) {
    const { onClick, variant = "light", isHidden = false } = props
    let themeClasses = arrowTheme[variant] || arrowTheme.default
    isHidden && (themeClasses += " !hidden")
    return (
        <button
            type="button"
            aria-label="Previous"
            className={`${arrowClass} ${themeClasses} ${prevArrowPosition} ${gaEventClasses.slider} `}
            onClick={onClick}
            data-action-detail={gaEventLabels.arrowLeft}
        >
            <ChevronLeft />
        </button>
    )
}

function NextArrow(props) {
    const { onClick, variant = "light", isHidden = false } = props
    let themeClasses = arrowTheme[variant] || arrowTheme.default
    isHidden && (themeClasses += " !hidden")
    return (
        <button
            type="button"
            aria-label="Next"
            className={`${arrowClass} ${themeClasses} ${nextArrowPosition} ${gaEventClasses.slider}`}
            onClick={onClick}
            data-action-detail={gaEventLabels.arrowRight}
        >
            <ChevronRight />
        </button>
    )
}

export { NextArrow, PrevArrow }

PrevArrow.propTypes = {
    onClick: PropTypes.func,
    variant: PropTypes.string,
    isHidden: PropTypes.bool,
}

NextArrow.propTypes = {
    onClick: PropTypes.func,
    variant: PropTypes.string,
    isHidden: PropTypes.bool,
}
