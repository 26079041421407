import React from "react"
import PropTypes from "prop-types"
import parse from "html-react-parser"

import { useGlobalContext } from "../../../../context/GlobalContext"
import { defaultStyle as style } from "./RecipePreparation-tw-styles"
import { v4 as uuidv4 } from "uuid"

const RecipePreparation = props => {
    const { id, steps } = props
    const { labels } = useGlobalContext()

    const renderPreparationSteps = steps => {
        if (!steps || !steps.length) return

        return steps.map(step => (
            <React.Fragment key={uuidv4()}>
                {step?.headline && <h2 className={style.itemHeadline}>{parse(step?.headline)}</h2>}
                <li className={style.item}>
                    <div className={style.icon}></div>
                    <div>{parse(step?.PreparationDescriptionItem)}</div>
                </li>
            </React.Fragment>
        ))
    }

    return (
        <div id={id ?? null} className={style.wrapper}>
            <h1 className={style.heading}>{labels?.recipesDetail?.preparation}</h1>
            <ul className={style.block}>{renderPreparationSteps(steps)}</ul>
        </div>
    )
}

RecipePreparation.propTypes = {
    id: PropTypes.string,
    steps: PropTypes.array,
}

export default RecipePreparation
