import React from "react"

function ChevronTop() {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.5003 15.9493L19.5003 17.9493L10.5003 8.94934L12.5003 6.94934L21.5003 15.9493Z"
                fill="#1D1D1B"
            />
            <path d="M5.50033 17.9493L3.50034 15.9493L12.5003 6.94934L14.5003 8.9493L5.50033 17.9493Z" fill="#1D1D1B" />
        </svg>
    )
}

export default ChevronTop
