import React from "react"
import PropTypes from "prop-types"
import { v4 as uuidv4 } from "uuid"

import { defaultStyle as style } from "./Tags-tw-styles"
import { TAGS_CONSTANT } from "../../../constants/mondelezAPI.constants"

export const TAG_CODES_MAP = {
    YELLOW: TAGS_CONSTANT.cookingType,
    BLUE: TAGS_CONSTANT.occasion,
    BEIGE: TAGS_CONSTANT.special
}

const Tags = (props) => {
    const { classifications } = props

    const getTagClass = (variant) => {
        switch (variant) {
            case TAG_CODES_MAP.YELLOW:
                return style.yellow
            case TAG_CODES_MAP.BLUE:
                return style.blue
            case TAG_CODES_MAP.BEIGE:
                return style.beige
            default:
                return null
        }
    }

    const mapTags = (tagsArray) => {
        if(!tagsArray || !tagsArray.length) return

        return tagsArray.map(item => {
            const getClassName = getTagClass(parseInt(item?.ParentId));
            return getClassName && <div key={uuidv4()} className={getClassName}>{item?.Name}</div>
        })
    }

    return mapTags(classifications)
}

Tags.propTypes = {
    classifications: PropTypes.array,
}

export default Tags
