import React from "react"
import PropTypes from "prop-types"

import { WaveLg, WaveMd } from "../../assets/icons/WaveDividerSizes"
import { defaultStyle as style } from "./WaveDivider-tw-styles"

export const DIRECTION = {
    TOP: "TOP",
    BOTTOM: "BOTTOM",
}

export const COLOR = {
    RED: "RED",
    DARK_RED: "DARK_RED",
    BURGUNDY: "BURGUNDY",
    BEIGE: "BEIGE",
}

const WaveDivider = props => {
    const { direction, color } = props

    const waveDirection = direction => {
        switch (direction) {
            case DIRECTION.TOP:
                return "[&_svg]:rotate-0 -mt-[39px] -mb-[1px] lg:-mt-[59px]"
            case DIRECTION.BOTTOM:
                return "[&_svg]:rotate-180 -mb-[39px] -mt-[1px] lg:-mb-[59px]"
            default:
                return "[&_svg]:rotate-0 -mt-[39px] -mb-[1px] lg:-mt-[59px]"
        }
    }

    const waveColor = color => {
        switch (color) {
            case COLOR.RED:
                return "[&_svg]:fill-red"
            case COLOR.DARK_RED:
                return "[&_svg]:fill-darkRed"
            case COLOR.BURGUNDY:
                return "[&_svg]:fill-burgundy"
            case COLOR.BEIGE:
                return "[&_svg]:fill-beige"
            default:
                return "[&_svg]:fill-red"
        }
    }

    return (
        <div className={`${style.wrapper} ${waveDirection(direction)} ${waveColor(color)}`}>
            <div className="hidden lg:block">
                <WaveLg />
            </div>
            <div className="lg:hidden">
                <WaveMd />
            </div>
        </div>
    )
}

WaveDivider.propTypes = {
    direction: PropTypes.string,
    color: PropTypes.string,
}

export default WaveDivider
