import React from "react"
import PropTypes from "prop-types"
import * as styles from "./Footer-tw-styles"
import { get } from "../../../helpers/utils"
import Image from "../../UI/Image/Image"
import { gaEventClasses, gaEventLabels } from "../../../constants/gtm.constants"
import Link from "../../UI/Link/Link"
import { v4 as uuidv4 } from "uuid"
import WaveDivider, { COLOR } from '../../WaveDivider/WaveDivider'

const Footer = props => {
    const { logo, mainLinksCollection, socialLinksCollection, copyRight, brandLogo } = props
    const style = styles.defaultStyle

    const mainLinks = mainLinksCollection && get(mainLinksCollection, "items", [])
    const socialLinks = socialLinksCollection && get(socialLinksCollection, "items", [])

    return (
        <>
            <WaveDivider color={COLOR.BURGUNDY} />
            <footer className={style.wrapper}>
                <div className={style.container}>
                    <div className={style.logoWrapper}>
                        <Image
                            contentfulImage={logo}
                            className={style.logoImage}
                            eventClass={gaEventClasses.logo}
                            eventLabel={gaEventLabels.logo}
                        />
                    </div>
                    <div className={style.columns}>
                        {mainLinks.map(column => (
                            <div className={style.column} key={uuidv4()}>
                                <h5 className={style.columnTitle}>{column.label}</h5>
                                {column.linksCollection.items.map(link => (
                                    <Link {...link} key={uuidv4()} className={style.navigationLink} />
                                ))}
                            </div>
                        ))}
                        {socialLinks.map(column => (
                            <div className={style.column} key={uuidv4()}>
                                <h5 className={style.columnTitle}>{column.label}</h5>
                                <div className={style.socialsWrapper}>
                                    {column.linksCollection.items.map(link => (
                                        <Link {...link} key={uuidv4()} className={style.navigationLink} />
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={style.lastRow}>
                        <div>
                            <Image
                                contentfulImage={brandLogo}
                                eventClass={gaEventClasses.brandLogo}
                                eventLabel={gaEventLabels.brandLogo}
                            />
                        </div>
                        <div>
                            <span className={style.copyRight}>{copyRight}</span>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

Footer.propTypes = {
    logo: PropTypes.object,
    mainLinksCollection: PropTypes.object,
    socialLinksCollection: PropTypes.object,
    copyRight: PropTypes.string,
    brandLogo: PropTypes.object,
}

export default Footer
