import React from "react"
import PropTypes from "prop-types"

import { defaultStyle as style } from "./SubscriptionFormImage-tw-styles"
import ResponsiveImage from "../../../UI/ResponsiveImage/ResponsiveImage"

const SubscriptionFormImage = props => {
    const { image } = props

    return (
        <div className={style.wrapper}>
            <ResponsiveImage contentfulImagesComponent={image} />
        </div>
    )
}

SubscriptionFormImage.propTypes = {
    image: PropTypes.object,
}

export default SubscriptionFormImage
