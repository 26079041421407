import React from "react"
import PropTypes from "prop-types"

import { defaultStyle as style } from "./RecipeSummary-tw-styles"

import { useGlobalContext } from "../../../context/GlobalContext"
import { DIFFICULTIES_CONSTANT } from "../../../constants/mondelezAPI.constants"
import parse from "html-react-parser"
import { getHumanReadableTime } from "../../../helpers/utils"

const RecipeSummary = props => {
    const { id, recipe } = props
    const { Servings, TimeSpans, Classifications } = recipe
    const { labels } = useGlobalContext()

    const availableDifficulties = [...Object.values(DIFFICULTIES_CONSTANT)]

    const getDifficulty = classificationArray => {
        const matchingItem = classificationArray.find(item => availableDifficulties.includes(item?.Id))
        return matchingItem?.Name || ""
    }

    return (
        <section id={id} className={style.wrapper}>
            <div className={style.content}>
                <div className={style.infoBoxWrapper}>
                    <div className={style.infoBox}>
                        <p className={style.infoBoxTitle}>{labels?.recipesDetail?.portionsNumber}</p>
                        <p className={style.infoBoxCaption}>{Servings}</p>
                    </div>
                    <div className={style.infoBox}>
                        <p className={style.infoBoxTitle}>{labels?.recipesDetail?.preparationTime}</p>
                        <p className={style.infoBoxCaption}>{getHumanReadableTime(TimeSpans?.prepTime)}</p>
                    </div>
                    <div className={style.infoBox}>
                        <p className={style.infoBoxTitle}>{labels?.recipesDetail?.difficulty}</p>
                        <p className={style.infoBoxCaption}>{getDifficulty(Classifications)}</p>
                    </div>
                </div>
                <p className={style.description}>{parse(recipe?.MoodText)}</p>
            </div>
        </section>
    )
}

RecipeSummary.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    recipe: PropTypes.object,
}

export default RecipeSummary
