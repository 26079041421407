import React from "react"
import PropTypes from "prop-types"
import parse from "html-react-parser"

import { useGlobalContext } from "../../../../context/GlobalContext"
import { defaultStyle as style } from "./RecipeIngredients-tw-styles"
import CheckMark from "../../../../assets/icons/12x12/CheckMark"
import { v4 as uuidv4 } from "uuid"

const RecipeIngredients = props => {
    const { id, list } = props
    const { labels } = useGlobalContext()

    const renderIngredients = ingredients => {
        if (!ingredients || !ingredients.length) return

        return ingredients.map(ingredient => (
            <React.Fragment key={uuidv4()}>
                {ingredient?.Subtitle && (
                    <li className={`${style.item} !font-semibold`}>{parse(ingredient?.Subtitle)}</li>
                )}
                <li className={style.item}>
                    <div className={style.itemIconWrapper}>
                        <CheckMark />
                    </div>
                    <p>
                        {parse(ingredient?.FullMeasure)} {parse(ingredient?.PrePreparation)}{" "}
                        {parse(ingredient?.IngredientName)} {parse(ingredient?.PostPreparation)}{" "}
                    </p>
                </li>
            </React.Fragment>
        ))
    }
    return (
        <div id={id ?? null} className={style.wrapper}>
            <h1 className={style.heading}>{labels?.recipesDetail?.ingredients}</h1>
            <ul className={style.block}>{renderIngredients(list)}</ul>
        </div>
    )
}

RecipeIngredients.propTypes = {
    id: PropTypes.string,
    list: PropTypes.array,
}

export default RecipeIngredients
