import React, { useState } from "react"
import PropTypes from "prop-types"
import { labels as recipeLabels } from "../../../constants/recipe.constants"
import * as styles from "./RecipeVideo-tw-styles"
import { gaEventClasses } from "../../../constants/gtm.constants"

const LANDSCAPE = "LANDSCAPE"
const PORTRAIT = "PORTRAIT"

const getVideoFromRecipe = recipe => {
    if (!recipe?.Videos || recipe?.Videos?.length === 0) return null
    const lanscapeFound = recipe.Videos.find(video => {
        return video?.VideoType === recipeLabels.VIDEO_LANDSCAPE_TYPE
    })
    if (lanscapeFound) return lanscapeFound
    const portraitFound = recipe.Videos.find(video => {
        return video?.VideoType === recipeLabels.VIDEO_PORTRAIT_TYPE
    })
    if (portraitFound) return portraitFound
    return null
}
const getIframeSrc = (video, variant) => {
    if (!video) return null
    switch (variant) {
        case LANDSCAPE:
            const youtubeIdLanscape = video?.VideoURL?.split("v=")[1]
            if (youtubeIdLanscape) return `https://www.youtube.com/embed/${youtubeIdLanscape}`
        case PORTRAIT:
            const youtubeIdPortrait = video?.VideoURL?.split("shorts/")[1]
            if (youtubeIdPortrait) return `https://www.youtube.com/embed/${youtubeIdPortrait}`
        default:
            return null
    }
}
function RecipeVideo({ recipe }) {
    const video = getVideoFromRecipe(recipe)
    const variant = video?.VideoType === recipeLabels.VIDEO_LANDSCAPE_TYPE ? LANDSCAPE : PORTRAIT
    const iframeSrc = getIframeSrc(video, variant)
    const style = styles[variant] || styles.defaultStyle
    const [buttonOverlayVisible, setButtonOverlayVisible] = useState(true)

    const playHandler = () => {
        const iframe = document.querySelector(`iframe[src="${iframeSrc}"]`)
        if (iframe) {
            iframe.src = iframeSrc + "?autoplay=1"
        }
        setButtonOverlayVisible(false)
    }
    if (!video || !iframeSrc) return null
    return (
        <div className={style.wrapper}>
            <div className={style.content}>
                <iframe
                    width="100%"
                    height="100%"
                    src={iframeSrc}
                    title={recipe.RecipeName}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
                {buttonOverlayVisible && (
                    <button
                        type="button"
                        aria-label="Play video"
                        className={`${style.buttonOverlay} ${gaEventClasses.video_play}`}
                        data-action-detail={video.VideoURL}
                        onClick={playHandler}
                    >
                        &nbsp;
                    </button>
                )}
            </div>
        </div>
    )
}

export default RecipeVideo

RecipeVideo.propTypes = {
    recipe: PropTypes.object,
}
